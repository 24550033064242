import Seo from 'src/components/seo/Seo'

function Atendimento() {
  const IframeAtendimento = () => (
    <>
      <iframe
        title="Atendimento"
        className="w-full block overflow-hidden border-0 min-h-screen iframe-atendimento"
        src="https://decathlon.custhelp.com/app/home"
        height="173.611vw"
      />
    </>
  )

  return (
    <>
      <Seo language="pt-br" title="Atendimento | Decathlon" />
      <IframeAtendimento />
    </>
  )
}

export default Atendimento
